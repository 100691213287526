body {
    width: 100vw;
    overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap");

@font-face {
    font-family: "SF-Pro-Display";
    src: url("../font/SFProDisplay-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "SF-Pro-Display";
    src: url("../font/SFProDisplay-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "SF-Pro-Display";
    src: url("../font/SFProDisplay-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "SF-Pro-Display";
    src: url("../font/SFProDisplay-Bold.ttf") format("truetype");
    font-weight: 700;
}

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*:not(.fa) {
    font-family: "SF-Pro-Display", sans-serif;
    color: #535353;
}

.button {
    color: white;
    padding: 0.7rem 1.5rem;
    border-radius: 5px;
    background-color: red;
    max-width: fit-content;
    cursor: pointer;
    transition: all 0.4s ease;
    border: 2px solid red;

    &:hover {
        color: #535353;
        background-color: transparent;
    }

    &.with-arrow::after {
        content: "\f0da";
        position: relative;
        font-size: 0.9rem;
        font-family: "Font Awesome 5 Pro";
        font-weight: 600;
        margin-left: 0.7rem;
    }
}

a {
    text-decoration: none;
}

.navbar-container {
    flex-direction: row;
    background-color: #1b1c1e;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.4s ease;
    left: 0px;
    right: 0px;

    &.fixed {
        position: fixed;
        transition: all 0.4s ease;
        z-index: 100;

        .top-container {
            display: none !important;
        }

        .mobile-navbar-toggle {
            .fa-bars,
            .fa-times {
                top: 3rem !important;
            }
        }
    }
    .page-contaainer {
        margin-top: 50px;
    }

    .nav-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 2rem 1rem;
        width: 100%;
        position: relative;
    }

    .logo {
        height: 5rem;
        width: 8rem;
        background-image: url(../img/logo-serrurier.png);
        background-repeat: no-repeat;
        background-size: 100%;
        // margin: 1rem 0;
        position: absolute;
        left: 7vw;
        top: 15px;
    }

    .mobile-navbar-toggle {
        display: none;
        .fa-bars,
        .fa-times {
            color: white;
            font-size: 1.6rem;
            position: absolute;
            right: 1.5rem;
            top: 6.5rem;
        }

        .fa-times {
            display: none;
        }

        &.open {
            background-color: red;
            .fa-bars {
                display: none !important;
            }
            .fa-times {
                display: flex !important;
            }
        }
    }

    .top-container {
        background: black;
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        padding: 12px 27px;

        .socials {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        i {
            margin-right: 0.5rem;
        }

        a {
            color: white;
            transition: all 0.5s ease;
            font-size: 1rem;

            &:hover {
                color: red;
            }
        }
    }

    .links-container,
    .mobile-navbar-container {
        padding: 0rem 7vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: fit-content;

        .nav-link,
        .mobile-nav-link {
            font-size: 1.15rem;
            color: white !important;
            padding: 7px 17px;
            border-radius: 5px;
            margin: 0px 5px;
            cursor: pointer;
            transition: all 0.4s ease;
            position: relative;
            overflow: hidden;
            &:after {
                position: absolute;
                height: 3px;
                content: "";
                left: 50%;
                bottom: 0px;
                transition: all 0.4s ease;
                width: 0%;
                background: white;
            }
        }

        .nav-link:hover,
        .nav-link.active {
            background-color: red;
            &:after {
                width: 100%;
                left: 0px;
            }
        }

        .mobile-nav-link.active {
            color: red !important;
        }
    }

    .mobile-nav-link {
        &:hover {
            color: red;
        }
        text-align: left;
    }

    .mobile-navbar-container {
        display: none;
        padding: 1rem;

        &:not(.active) {
            .mobile-nav-link {
                color: #1b1c1e !important;
            }
        }

        &.active {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100vw;
            animation: 0.5s slideInTop;
        }
    }
}

@keyframes slideInTop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 980px) {
    .links-container {
        display: none !important;
    }

    .mobile-navbar-toggle {
        display: flex !important;
    }

    .navbar-container {
        align-items: unset;
    }
}

////////////Footer/////////
.footer-container {
    padding: 2rem 7vw;
    background-color: #1b1c1e;
    color: white;

    .footer-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // flex-wrap: wrap;

        .row {
            width: 330px;
            margin: 1rem;
            display: flex;
            flex-direction: column;

            &:first-of-type {
                padding-right: 0.5rem;
            }

            .text {
                color: #cecece;
                font-size: 0.97rem;
                line-height: 1.65;
                text-align: justify;
            }

            .contact-item {
                font-size: 1rem;
                margin: 0.5rem 0;
                .fa,
                .fal,
                .far {
                    font-size: 0.9rem;
                    margin-right: 0.6rem;
                }
            }

            .social-links-container {
                display: flex;
                flex-direction: row;
                .social-link i {
                    margin: 0;
                    padding: 0;
                    height: 1.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.8rem;
                    border-radius: 50%;
                    margin: 0.5rem;
                    background-color: grey;
                    transition: all 0.4s ease;
                    cursor: pointer;

                    &:hover {
                        background-color: red;
                        color: white;
                    }
                }
            }

            .row-item {
                font-size: 1.1rem;
                margin-bottom: 0.5rem;
                color: white;
                cursor: pointer;
                transition: all 0.4s ease;
                text-decoration: none;

                &:hover {
                    color: red;
                }

                &::before {
                    content: "\f00c";
                    position: relative;
                    font-weight: 600;
                    font-size: 0.85rem;
                    margin-right: 0.5rem;
                    font-family: "Font Awesome 5 Pro";
                    color: red;
                }
            }
        }

        .row-title {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 1.3rem;
            color: white;
            // text-transform: uppercase;
        }
    }

    .copyright {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 0.5rem;

        .left-side {
            font-size: 0.8rem;
            font-weight: 300;
        }
    }

    i {
        margin-right: 0.9rem;
    }

    a {
        color: white;
        transition: all 0.5s ease;

        &:hover {
            color: red;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    .col-6 {
        width: 50%;
    }
}

ul.list1 {
    list-style: none;
    @media (max-width: 768px) {
        margin-left: 0px !important;
    }
    li {
        &::before {
            content: "\f3c2";
            position: relative;
            font-weight: 600;
            font-size: 0.85rem;
            margin-right: 0.5rem;
            font-family: "Font Awesome 5 Pro";
            color: red;
        }
    }
}

.floating-number {
    background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 0.6rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
        background-color: #1b1c1e;
        color: white !important;
    }
    &:hover a {
        color: white !important;
        text-decoration: none !important;
    }
    a {
        color: white;
        font-weight: 600;
    }
    i {
        margin-right: 0.7rem;
        color: white;
    }
}

@media (max-width: 1200px) {
    .footer-container .footer-inner {
        flex-wrap: wrap;
    }
}
///////Slider////

.slider-container {
    min-height: fit-content;
    background-image: url(../img/slider-bg3.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    background-color: #29292923;
    background-blend-mode: multiply;
    // padding-top: 150px;

    #slider-left {
        width: 50%;
        padding: 50px;
    }

    #slider-right {
        width: 50%;
        padding: 50px;
        background: rgba(255, 255, 255, 0.281);
        display: grid;
        place-items: center;
    }

    .caption {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
            font-size: 2.9rem;
            text-transform: uppercase;
            color: white;
            font-family: "Oswald", sans-serif;
        }

        .subheading {
            font-size: 1.5rem;
            font-family: "Roboto", sans-serif;
            text-transform: capitalize;
            font-weight: 500;
            font-style: italic;
            margin-top: 5px;
            color: white;
        }

        .button {
            margin-top: 3rem;
        }

        .form-container {
            margin: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center !important;
            flex-wrap: wrap;

            input,
            select {
                padding: 1rem;
                border-radius: 3px;
                border: none;
                margin: 0.25rem;

                &:focus {
                    outline: none;
                }
            }
            input[type="submit"] {
                background-color: red;
                color: white;
                cursor: pointer;
                transition: all 0.4s ease;

                &:hover {
                    background-color: #1b1c1e;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .slider-container {
        flex-direction: column !important;
    }
    #slider-left,
    #slider-right {
        width: 100% !important;
    }
}

.input-container-slider {
    width: 100%;
    .form-group {
        width: 48%;
        margin: 1%;
        float: left;
    }
}

.submitBtn {
    background-color: red;
    color: white !important;
    border: 0px solid red;
    border-radius: 5px;
    padding: 7px 17px;
    margin-top: -0.2rem !important;
    margin-left: -1rem !important;
    cursor: pointer;
    transition: all 0.4s ease;
    font-family: "Roboto Slab", sans-serif;

    &:hover {
        cursor: pointer;
        background-color: #1b1c1e;
        border-color: #1b1c1e;
    }
}

.input-container-form {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
}

@media (max-width: 760px) {
    .slider-container .form-container {
        flex-direction: column !important;
        margin-top: 1.5rem !important;
        input,
        select {
            width: 90vw;
        }
    }
}

//////?Section Styles///////

.section {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 7vw;
    max-width: 1440px;
    margin: auto;

    @media (max-width: 767px) {
        padding: 1.5rem 15px;
    }

    &.fit {
        min-height: fit-content !important;
    }

    ul {
        margin-right: auto;
        margin-top: 2rem;
        margin-left: 2rem;

        li {
            font-weight: 600;
            margin: 0.8rem 0;

            span {
                font-weight: 400;
            }
        }
    }

    &.white {
        background-color: white !important;
    }
}

.subtext {
    font-size: 0.9rem;
    margin: 1rem 0;
}

.text {
    font-size: 1.1rem;
    margin: 0rem 0 1.5rem 0;

    span {
        font-weight: 700;
        color: #535353;
    }

    &.center {
        text-align: center;
        margin-top: 1rem;
    }
}

.theme-text {
    font-size: 0.95rem;
    color: red;
    font-weight: 600;
    margin: 1rem;
}

.section-heading {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 500;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 2rem 0;
    font-family: "Roboto Slab", sans-serif;

    &::before,
    &::after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: "\a0";
        background-color: black;
    }

    &::after {
        margin-left: 2%;
    }

    &::before {
        margin-left: -52%;
        text-align: right;
    }
}

.section-subheading {
    font-size: 1.7rem;
    text-align: center;
    margin: 2rem;
    font-weight: 600;
}

.list-subheading {
    font-size: 1.3rem;
    margin: 2rem;
    font-weight: 600;
}

.title-with-after {
    font-size: 1.9rem;
    font-weight: 600;
    padding-bottom: 1rem;
    position: relative;
    text-align: center;

    &::after {
        content: "";
        position: absolute;
        bottom: 0rem;
        width: 5rem;
        left: calc(50% - 2.5rem);
        height: 3px;
        background-color: #535353;
    }
}

.subsection-text {
    font-size: 1.1rem;
    text-align: center;
}

.service-section {
    .videos-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow-x: scroll;
        max-width: 100vw;

        &::-webkit-scrollbar {
            height: 3px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            background-color: red;
            border-radius: 3px !important;
        }

        .video {
            margin: 1rem;
            height: 340px;
            min-width: 530px;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.why-choose-us-section {
    display: flex;
    flex-direction: column;

    .boxes-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &.fit {
            .box {
                width: 25%;
                min-height: 470px;
            }
        }

        .box {
            width: 370px;
            background-color: white;
            display: flex;
            padding: 25px 0px;
            flex-direction: column;
            align-items: center;
            background-color: #ffffff;
            justify-content: center;
            margin-top: 39px !important;
            border-right: 1px solid #ccc;
            text-align: justify;

            &:last-of-type {
                border-right: none;
            }

            i {
                font-size: 5rem;
                margin-bottom: 2rem;
                margin-top: 4rem;
            }

            .caption {
                font-size: 1.8rem;
                text-align: justify;
            }

            .caption-text {
                font-size: 1.1rem;
                text-align: justify;
                margin-top: 1rem;
                font-weight: 300;
                padding: 1rem;
            }
        }
    }
}

@media (max-width: 1120px) {
    .boxes-container.fit {
        flex-wrap: wrap;
        .box {
            width: 50% !important;
        }
    }
}

@media (max-width: 760px) {
    .boxes-container.fit {
        .box {
            width: 90% !important;
        }
    }
    #page-not-found {
        padding: 220px 100px;
    }
}

@media (max-width: 400px) {
    .boxes-container .box {
        max-width: 90vw;
    }

    .videos-container .video {
        min-width: 95vw !important;
        max-width: 95vw !important;
    }
}

///////////Other Pages///////
.header-container {
    min-height: 300px;
    background-image: url(../img/headerBg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #1b1c1e3d;
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;

    .caption {
        font-size: 2.1rem;
        text-align: center;
        font-weight: 500;
        color: white;
    }

    i {
        position: absolute;
        bottom: 0.5rem;
        cursor: pointer;
        transition: all 0.4s ease;
        transform: translateY(-0.5rem);

        &:hover {
            transform: translateY(0rem);
        }
    }
}

.service-page {
    .image-section {
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 7vw;
        background-color: #f7f7f7;

        img {
            width: 100%;
            height: 100%;
            max-height: 300px;
            max-width: 500px;
            object-fit: contain;
            transition: all 0.4s ease;
            // cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .large-image-section {
        img {
            margin: 1rem;
            height: fit-content;
            width: 100%;
            min-height: 30rem;
        }
    }
}

///////////service-page //////

.question-and-video-section,
.guarantee-section {
    .question {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;
        margin: 2rem 0;

        .title {
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 2rem;
        }
        .subtext {
            font-weight: 600;
        }

        iframe {
            margin: 0 1rem;
        }

        .item {
            font-size: 1.2rem;
            margin: 0.5rem 0;

            &::before {
                content: "\f00c";
                position: relative;
                font-family: "Font Awesome 5 Pro";
                color: red;
                font-size: 0.9rem;
                font-weight: 700;
                margin-right: 0.5rem;
            }
        }
        .item1 {
            font-size: 1.2rem;
            margin: 0.5rem 0;
            text-align: justify;
            &::before {
                content: "\f00c";
                position: relative;
                font-family: "Font Awesome 5 Pro";
                color: red;
                font-size: 0.9rem;
                font-weight: 700;
                margin-right: 0.5rem;
            }
        }

        .text-side {
            width: 50%;
        }
    }
}

@media (max-width: 980px) {
    .question {
        margin: 2rem 0 !important;
        flex-direction: column !important;
        * {
            width: 100% !important;
        }

        iframe {
            margin: 2rem 0 !important;
        }
    }

    .devis-info-section {
        flex-direction: column !important;
    }
}

.devis-info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .info-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .devis-info-box {
        background-color: white;
        border-radius: 4px;
        padding: 2rem;
        margin: 0.5rem;
        max-width: 400px;

        @media (max-width: 767px) {
            margin: 0.5rem 0px;
        }

        .location {
            font-size: 1.7rem;
            font-weight: 500;
            margin-bottom: 2rem;
            text-align: center;
        }

        .time {
            font-family: "Roboto", sans-serif;
            margin: 0.5rem 0;
        }

        .info-text {
            color: #1b1c1e;

            .highlight {
                font-size: 1.3rem;
                font-weight: 500;
            }
        }
    }
}

.notre-intervention-section {
    width: 100vw;
    padding: 0;
    position: relative;
    .box {
        width: 100%;
        // margin: 0 4rem;
        padding: 1rem 5.4rem;

        // &:first-of-type {
        //   max-width: 500px;
        //   position: relative;
        //   top: 0;
        // }

        .inner-items-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .inner-item {
                width: 15rem;
                height: 18rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 0.5rem 0.8rem;
                background-color: white;
                @media (max-width: 767px) {
                    width: 100%;
                    margin: 0.5rem 0px;
                }
                padding: 1rem;
                position: relative;
                z-index: 1;
                &:hover .anim {
                    transform: scale(1);
                }
                &:hover p {
                    color: white;
                }
                &:hover i {
                    color: white;
                }
                .anim {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: red;
                    transform: scale(0);
                    transition: all 0.5s;
                }
                i {
                    color: red;
                    z-index: 2;
                    transition: all 0.5s;
                }
                p {
                    text-align: center;
                    font-size: 0.9rem;
                    z-index: 2;
                    transition: all 0.5s;
                }
                .num {
                    font-size: 1.2rem;
                    font-weight: 700;
                    color: red;
                    z-index: 2;
                    transition: all 0.5s;
                }
                .bold {
                    font-weight: 600;
                }

                i {
                    font-size: 3rem;
                    margin: 1rem 0;
                    align-self: center;
                }
            }
        }

        .section-heading {
            font-size: 2.5rem;
            text-align: center;
            font-weight: 500;
            width: 100%;
            overflow: hidden;
            position: relative;
            margin: 2rem 0;
            font-family: "Roboto Slab", sans-serif;
            @media (max-width: 960px) {
                font-size: 1.8rem;
            }

            &::before,
            &::after {
                position: absolute;
                top: 51%;
                overflow: hidden;
                width: 50%;
                height: 1px;
                content: "\a0";
                background-color: black;
            }

            &::after {
                margin-left: 2%;
            }

            &::before {
                margin-left: -52%;
                text-align: right;
            }
        }

        .text {
            font-size: 1rem;
            line-height: 1.7;
        }
    }
}

@media (max-width: 1100px) {
    .notre-intervention-section {
        flex-direction: column !important;

        .box:first-of-type {
            max-width: 100%;
        }
    }
}

@media (max-width: 500px) {
    .notre-intervention-section .box {
        padding: 4rem 1rem !important;
    }
}

.social-links-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 7vw;
    .right-side {
        display: flex;
        flex-direction: row;

        i {
            height: 4rem;
            width: 4rem;
            background-color: #535353;
            margin: 0.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            color: white;
            transition: all 0.4s ease;
            border: 1px solid #535353;

            &:hover {
                background-color: transparent;
                color: #535353;
            }
        }
    }
}

@media (max-width: 920px) {
    .social-links-section {
        flex-direction: column;

        .right-side {
            margin-top: 3rem;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.door-opening-section {
    align-items: flex-start;
    padding: 5rem 7vw;

    @media (max-width: 768px) {
        padding: 5rem 15px;
    }

    .title-with-after {
        margin-bottom: 2rem;
    }
}

.guarantee-section,
.door-opening-section {
    .title,
    .title-with-after,
    .text,
    .item,
    .subtext {
        text-align: center !important;
        margin-left: auto;
        margin-right: auto;
    }
}

.service-slider-section {
    margin-top: 4rem;
    .button {
        margin: 2rem auto;
    }
    .inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 50px;
        .box {
            height: 260px;
            max-width: 260px;
            width: 100%;
            display: flex;
            flex-direction: auto;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all 0.4s ease;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &::after {
                content: attr(data-caption);
                height: 100%;
                font-size: 1.5rem;
                text-transform: uppercase;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: white;
                width: 100%;
                padding: 0.5rem;
                position: absolute;
                background-color: #535353a9;
                cursor: pointer;
                transition: all 0.4s ease;
            }

            &:hover {
                transform: scale(1.05);
                &::after {
                    opacity: 0;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .service-slider-section .inner-container {
        padding: 2rem 0.25rem;
        .box {
            max-width: 97vw !important;
        }
    }
}

////////

.tarrif-section {
    padding-top: 3rem;
    align-items: unset;
    margin: 5rem auto;

    .title-with-after {
        font-size: 1.4rem;
        margin: auto;
        margin-bottom: 2rem;

        &::after {
            bottom: 0;
            height: 2px;
        }
    }
    .tarrif-boxes-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .tarrif-box {
            min-height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 350px;
            margin: 18px 0px 1.5rem 0px;

            .tarrif-title {
                font-size: 1.25rem;
                font-weight: 600;
                position: relative;
                margin-bottom: 2rem;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -1.5rem;
                    left: calc(50% - 2.5rem);
                    height: 2px;
                    width: 5rem;
                    background-color: #cccccccc;
                }
            }

            .info {
                font-size: 1.2rem;
                color: #535353a9;
                margin: 0.3rem;
            }
        }
    }
}
// .section2{
//   background-image:url(../../assets/img/tarif.jpg);
//   object-fit:cover;
//   background-repeat: no-repeat;
//   opacity:0.33;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 1.5rem 7vw;
//     max-width: 1440px;
//     margin: auto;
// }
// .fond{
//   background-image:url(../../assets/img/tarif.jpg);
//   opacity:0.33;
//   object-fit:cover;
//   background-repeat: no-repeat;
// }

.product-info-section {
    align-items: unset;
    min-height: 80rem;
    background-color: #1b1c1e;
    display: flex;
}

.slide {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    width: 100% !important;

    .image {
        max-height: 40rem;
        margin-bottom: 2rem;

        img {
            height: 100%;
            width: 100%;
            max-height: 40rem;
            max-width: 100%;
        }
    }

    .left-side,
    .right-side {
        max-width: 50%;
        width: 50%;
        padding: 1rem;

        * {
            color: #e2e2e2;
        }

        .text {
            font-size: 1.05rem;
            line-height: 1.65;
            font-weight: 300;
        }

        .slide-title {
            font-size: 2rem;
            margin-bottom: 2rem;
            font-weight: 500;
        }
    }
}

@media (max-width: 1100px) {
    .product-info-section .slide {
        flex-direction: column !important;

        .left-side,
        .right-side {
            width: 100% !important;
            min-width: 100% !important;
        }
    }
}

.site-watermark-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .left-side {
        height: 15rem;
        width: 15rem;
        background-image: url(../img/logo-serrurier.png);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: 1.5rem;
    }

    .right-side {
        display: flex;
        flex-direction: column;
        margin: 1.5rem;

        .text {
            margin-top: 2rem;
        }
    }
}

.product-list-section {
    align-items: unset;

    .title-with-after {
        text-align: center;
        max-width: fit-content;
        margin: auto;
    }

    .product-list-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 4rem 0;
        justify-content: space-around;
        width: fit-content;
        .product-list-box {
            height: 360px;
            width: 300px;
            margin: 1px;

            img {
                object-fit: cover;
                width: 300px;
                height: 270px;
                margin-bottom: 1rem;
                position: relative;
                filter: grayscale(60%);
                transition: all 0.5s ease;
            }

            .image-container {
                position: relative;
                cursor: pointer;
                overflow: hidden;

                &::after {
                    content: "";
                    position: absolute;
                    height: 270px;
                    top: 0;
                    width: 100%;
                    background-color: #1b1c1e70;
                    transition: all 0.5s ease;
                }

                &:hover {
                    &::after {
                        background-color: transparent;
                    }
                    img {
                        filter: grayscale(0%);
                        transform: scale(1.05);
                    }
                }
            }

            .title {
                font-size: 1.2rem;
                border-bottom: 1px solid #cccccccc;
                padding-bottom: 0.5rem;
                font-weight: 600;
                min-height: 60px;
                position: relative;
                bottom: 0;
            }
        }
        @media (max-width: 767px) {
            a {
                width: 100%;
                .product-list-box {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.blog-list-section {
    .section-heading {
        font-size: 1.85rem;
        font-weight: 600;

        &::after,
        &::before {
            display: none;
        }
    }

    .blog-list-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .blog-item {
        height: 200px;
        max-width: 550px;
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
        position: relative;
        border: 1px solid #cccccccc;
        margin: 0.5rem 0.7rem;

        img {
            height: 100%;
            max-width: 180px;
            margin-right: 0.5rem;
        }

        .blog-title {
            font-size: 1.3rem;
            margin-bottom: 0.5rem;
            font-weight: 600;
        }

        .blog-preview-text {
            text-justify: newspaper;
            line-height: 1.5;
            color: grey;
        }

        .cta-text {
            font-size: 1.15rem;
            position: absolute;
            bottom: 0.5rem;
            color: red;
            cursor: pointer;
            transition: all 0.4s ease;

            &::after {
                content: "\f061";
                font-family: "Font Awesome 5 Pro";
                position: relative;
                font-size: 0.85rem;
                margin-left: 0.5rem;
                right: 0;
                font-weight: 600;
            }

            &:hover::after {
                transform: translateX(5px);
            }
        }
    }
}

@media (max-width: 600px) {
    .blog-item {
        flex-direction: column !important;

        .blog-preview-text {
            max-height: 100px;
            overflow: hidden;
        }

        img {
            display: none;
        }
    }
}

.zone-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    img {
        max-width: 40vw;
        object-fit: contain;
        min-height: 35rem;
    }

    .left-side {
        min-height: 30vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 3rem;
    }

    .zone-item {
        font-size: 1.1rem;
        position: relative;
        margin: 0.4rem 1rem;
        cursor: default;

        &::before {
            content: "\f00c";
            position: relative;
            font-weight: 600;
            font-size: 0.85rem;
            margin-right: 0.5rem;
            font-family: "Font Awesome 5 Pro";
        }

        // &:after {
        //   content: "";
        //   position: absolute;
        //   bottom: -0.25rem;
        //   height: 1px;
        //   width: 100%;
        //   left: 2px;
        //   background-color: red;
        // }
    }
}

.contact-page-container {
    display: flex;
    padding: 6rem 7vw;
    padding-top: 10rem;
    background-color: #f2f2f2;

    .side-title {
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .left-side,
    .right-side {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .item {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        i {
            height: 5.5rem;
            width: 5.5rem;
            min-width: 5.5rem;
            background-color: red;
            border-radius: 50%;
            color: white;
            font-size: 1.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .right {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 1rem;

            .title {
                font-size: 1.2rem;
                font-weight: 500;
                margin-bottom: 0.2rem;
            }

            .info {
                color: #535353a9;
                font-weight: 500;
            }
        }
    }

    .form-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;

        input[type="text"],
        input[type="email"],
        textarea {
            width: 100%;
            padding: 6px 12px;
            color: #555;
            padding: 1rem;
            margin: 0.5rem 0;
            border: none;
            border-radius: 3px;

            &.half {
                width: calc(50% - 1rem);

                &:nth-of-type(even) {
                    margin-left: 2rem;
                }
            }
        }

        .checkbox {
            font-size: 0.85rem;
            margin-top: 0.5rem;
        }
    }

    button {
        border: none;
        padding: 1rem 1.5rem;
        border-radius: 5px;
        background-color: #1b1c1e;
        color: white;
        margin: auto;
        margin-top: 1rem;
        text-align: center;
        background-color: #665;
        transition: all 0.4s ease;
        cursor: pointer;

        &:hover {
            background-color: #1b1c1e;
        }
    }
}

@media (max-width: 960px) {
    .contact-page-container {
        flex-direction: column !important;

        .left-side,
        .right-side {
            width: 100%;
        }
    }
}

.devis-form-container {
    max-width: 1200px;
    margin: auto;
    min-height: 500px;
    margin: 5rem auto;
    padding: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;

    .heading {
        font-size: 1.6rem;
        font-weight: 500;
    }

    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #ccc;
        padding: 0;
        margin-bottom: 3rem;
    }

    hr:last-of-type {
        margin-top: 1rem;
    }

    input,
    select,
    textarea {
        width: 100%;
        margin-left: 1rem;
        padding: 0.7rem 1rem;
        width: 100%;
        min-height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px #ccc;
        -webkit-transition: border-color ease-in-out 0.15s,
            box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

        ::placeholder {
            font-size: 1.1rem;
        }
    }

    label {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 800px;
        margin-top: 1rem;
        margin: 0.5rem auto;
        max-height: fit-content;
        font-weight: 600;
        align-items: center;
        justify-content: flex-start;

        &:last-of-type {
            align-items: flex-start;

            textarea {
                margin-left: 1.5rem;
            }
        }
    }

    .input-container {
        display: flex;
        width: 100%;
        flex-direction: row;

        input {
            width: 30% !important;
        }
    }

    .button {
        width: 100%;
        min-width: 90%;
        padding: 1rem;
        text-align: center;
        margin: 1rem auto;
        background-color: #5bc0de;
        border: 0;
        border-radius: 5px;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media (max-width: 500px) {
    .devis-form-container .input-container {
        flex-direction: column;

        input {
            margin: 0.5rem;
            min-width: 100%;
        }
    }
}

@media (max-device-width: 600px) {
    .input-container-form {
        flex-direction: column !important;
    }

    .form-group {
        margin: 0.5rem;
        min-width: 70%;
    }

    .checkbox {
        padding: 5%;
    }

    .checkbox span {
        font-size: 70%;
    }
    .section-heading::before {
        display: none;
    }
    .section-heading::after {
        display: none;
    }

    .devis-info-section .info-container {
        flex-direction: column;
    }

    .notre-intervention-section .box .inner-items-container {
        justify-content: center;
    }
}

.service-list-section {
    max-width: 100vw;
    padding: 5rem 0 !important;

    .section-heading,
    .subsection-text {
        padding: 0 7vw !important;
    }
    .service-list-items-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 4rem;
        overflow: hidden;

        .box {
            width: 25%;
            height: 460px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            flex-direction: column;
            transition: all 0.4s ease;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background-size: cover;

            // &::after,&::before {
            //   content: "";
            //   position: absolute;
            //   height: 100%;
            //   width: 100%;
            //   background: -webkit-linear-gradient(
            //     left,
            //     rgba(255, 255, 255, 0) 0%,
            //     rgba(255, 255, 255, 0.1) 10%,
            //     rgba(255, 255, 255, 0) 100%
            //   );
            //   background: linear-gradient(
            //     to right,
            //     rgba(255, 255, 255, 0) 0%,
            //     rgba(255, 255, 255, 0.1) 10%,
            //     rgba(255, 255, 255, 0) 100%
            //   );
            //   -webkit-transform: skewX(45deg);
            //   transform: skewX(45deg);
            // }

            &:nth-of-type(1) {
                background-image: url(../img/service_image_1.jpeg);
            }

            &:nth-of-type(2) {
                background-image: url(../img/service_image_2.jpeg);
            }

            &:nth-of-type(3) {
                background-image: url(../img/service_image_3.webp);
            }

            &:nth-of-type(4) {
                background-image: url(../img/service_image_4.webp);
            }

            .caption {
                color: white;
                font-size: 2.5rem;
                font-family: "Roboto Slab", sans-serif;
                text-align: center;
                line-height: 1.1;
            }

            .subtext {
                color: white;
                text-align: center;
                font-size: 1.2rem;

                strong {
                    color: white !important;
                }
            }

            &:hover {
                transform: scale(1.1);

                // &::after,&::before{
                //   background-position: 50% 50%;
                //   background-repeat: no-repeat;
                //   opacity: 1 !important;
                // }

                .button {
                    opacity: 1;
                }
            }

            .button {
                font-size: 1rem;
                padding: 0.7rem 1rem;
                padding: 0.7rem 1.5rem;
                border-radius: 5px;
                background-color: #ffe033;
                color: black;
                border: none;
                opacity: 0;
                margin-right: auto;
                margin-left: 1rem;

                &::after {
                    content: "\f0da";
                    position: relative;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 700;
                    font-size: 1.2rem;
                    top: 1px;
                    color: black;
                    transition: all 0.4s ease;
                    margin-left: 0.5rem;
                }

                &:hover {
                    color: white;
                    background-color: black !important;
                    &::after {
                        color: white;
                        margin-left: 0.9rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1110px) {
    .service-list-items-container {
        flex-wrap: wrap;
        overflow: hidden;
        .box {
            max-width: 50% !important;
        }
    }
}

@media (max-width: 700px) {
    .service-list-items-container {
        padding: 1rem;
        .box {
            max-width: 100% !important;
        }
    }
}

.testimony-section {
    padding: 0;
    background-image: url(../img/testi_cover.jpeg);
    background-size: cover;
    background-repeat: no-repeat;

    .subheading,
    .subsection-text {
        text-align: center;
        padding: 2rem 7vw;
    }

    * {
        color: white;
    }
}
.testimony-container {
    padding: 5rem 7vw;

    .testimony {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2rem;
        img {
            height: 10rem;
            width: 10rem;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 1.4rem;
        }

        .right-side {
            .name {
                font-size: 1.6rem;
                color: #ffe033;
                font-weight: 500;
                margin-bottom: 1rem;
                font-family: "Roboto Slab", sans-serif;
            }
            .quote {
                font-size: 1.2rem;
                font-family: "Roboto Slab", sans-serif;
            }
        }
    }
}

@media (max-width: 800px) {
    .testimony-container .testimony {
        flex-direction: column;

        .right-side {
            margin-top: 1rem;

            .name {
                text-align: center;
            }
        }
    }
}

///metions-Legales Page
.legal-box {
    min-width: 100%;
    border: 1px solid #cccccccc;
    margin: 0.75rem 0;

    .box-heading {
        width: 100%;
        background-color: #0397d9;
        color: white;
        padding: 0.5rem 1.5rem;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .box-body {
        margin: 1.5rem;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: space-around;

        .box-name {
            font-size: 2.1rem;
            text-transform: uppercase;
            margin-bottom: 2.5rem;
        }
    }
}

.custom-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    max-width: 400px;
    margin: 2rem 1rem;
    margin-top: 0.5rem;

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr td:nth-child(odd) {
        background-color: burlywood;
        color: white;
    }
}

.form-response {
    width: 100%;
    height: 120%;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    opacity: 0.7;
    font-family: "Open Sans", sans-serif;

    &.success {
        background-color: #008000bb;
        display: flex;
        color: white !important;
    }

    span {
        margin-left: 5px;
        margin-top: 1px;
    }

    &.error {
        background-color: #ff0000ab;
        display: flex;
        color: white !important;
    }
}

#rgpd_modal {
    position: fixed;
    left: 50%;
    top: -50%;
    transform: translate(-50%, -50%);
    background: #f7f7f9;
    padding: 25px;
    border-radius: 10px;
    opacity: 0;
    transition: 0.4s;
    box-shadow: 0px 0px 10px #cccccc;
    &.active {
        top: 50%;
        opacity: 1;
    }
    a.button {
        color: white;
        &:hover {
            color: black;
        }
    }
}

.rgpd_toggle {
    cursor: pointer;
}

.g-recaptcha {
    div {
        margin: auto;
    }
}

.notre-entreprise-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .box {
        display: flex;
        padding: 25px 15px;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        justify-content: center;
        margin-top: 39px !important;
        border-right: 1px solid #ccc;
        text-align: justify;
        width: 25%;

        i {
            font-size: 3rem;
            margin-bottom: 25px;
        }
        &:nth-child(2),
        &:nth-child(3) {
        }
    }
}
.box1:hover {
    transform: scale(1.1);
    transition: all 0.4s ease;
}

#img-01,
#img-02,
#img-03,
#img-04 {
    cursor: none !important;
}

#page-not-found {
    padding: 220px 150px;
}

#img-id {
    display: none;
}

#iframe-to-display {
    display: block;
}

.iframe-to-hide {
    display: none;
}

@media (max-width: 760px) {
    #page-not-found {
        padding: 220px 70px;
    }
}

p {
    text-align: justify;
}
.photo {
    box-shadow: 0px 2px 10px black;
}
.p-white {
    text-align: justify;
    color: white;
}

@media (max-width: 475px) {
    .BadgeContainer__Inner-sc-pa6z2-0 {
        transform: scale(0.6);
        transform-origin: left bottom;
    }
}

.g-recaptcha {
    margin-top: 100px;
}

@media (max-width: 768px) {
    #slider-right {
        display: block !important;
        padding: 0px !important;
    }
    .demo_main-form {
        width: 100%;
        h2,
        h2 b {
            color: white;
            text-align: center;
        }
    }
    .input-container-slider .form-group {
        width: 90%;
        display: block;
        margin: 10px auto;
        float: none;
    }
    .g-recaptcha {
        margin-top: 0px;
    }
    .navbar-container .nav-content {
        justify-content: space-between;
        .logo {
            position: relative;
            left: auto;
            top: auto;
        }
    }
    .service-list-items-container {
        flex-wrap: wrap;
        .box {
            width: 100% !important;
        }
    }
    .notre-entreprise-box {
        .box {
            min-width: 100%;
        }
    }
    .floating-number {
        bottom: 40px;
    }
    .dPBcPr {
        margin-bottom: 40px !important;
    }
    .service-slider-section .inner-container {
        margin-left: 0px !important;
    }
}
@media (max-width: 768px) {
    body {
        padding-bottom: 100px;
    }
}

#jumbotron {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url("../img/bg.jpg");
    backdrop-filter: grayscale(.5);
    background-size: cover;
    background-position: center center;
    padding: 50px 0px;
    .col-md-6 {
        background: white;
        border-radius: 10px;
        padding: 30px;
        p:last-child {
            margin-bottom: 0px;
        }
    }
}

section.padd {
    padding: 50px 0px;
}

.item {
    padding: 15px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    height: 150px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    display: grid;
    place-items: center;
    margin-bottom: 30px;
    transition: 0.3s ease-out;
    p {
        text-align: center;
        margin: 0px;
    }
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.bg-01{
    background: rgba(0, 0, 0, 0.1);
    height: 200px;
    display: grid;
    place-items: center;
    padding: 30px !important;
    p, strong{
        margin: 0px;
    }
}

.bg-02{
    background: rgba(0, 0, 0, 0.4);
    height: 200px;
    display: grid;
    place-items: center;
    padding: 30px !important;
    p, strong{
        color: white;
        margin: 0px;
    }
}

.bg-03{
    background: rgba(0, 0, 0, 0.6);
    height: 200px;
    display: grid;
    place-items: center;
    padding: 30px !important;
    p, strong{
        color: white;
        margin: 0px;
    }
}


.bg-04{
    background: rgba(0, 0, 0, 0.8);
    height: 200px;
    display: grid;
    place-items: center;
    padding: 30px !important;
    p, strong{
        color: white;
        margin: 0px;
    }
}

.m-t-5{
    margin-top: -5rem;
    display: flex;
    justify-content: flex-start !important;
}